import React from 'react';
import { FormFeedback, FormGroup, Label, Input } from 'reactstrap';

export default class Field {

    constructor(keyUpCallback) {
        this.keyUpCallback = keyUpCallback;
        this.doFormatControl = keyUpCallback != null;
    }

    handleKeyUp = (e) => {
        if (this.keyUpCallback) {
            this.keyUpCallback(e);
        }
    }

    getDisplayName = (item) => {
        return localStorage.getItem('lang') === 'sv'?
            item.displayname_sv : item.displayname;
    }

    getDefaultDate(item) {
        const date = new Date();
        if (!isNaN(parseInt(item.values))) {
                date.setDate(date.getDate() + parseInt(item.values));
        }
        return date.toJSON().slice(0,10);
    } 

    getDateForm(item, isSearch) {
        const showAsValid = this.doFormatControl && !item.mandatory;
        const showAsInvalid = this.doFormatControl && item.mandatory;
        let errText = "Ex: " + item.placeholder + ".";
        if (item.mandatory) errText = "Mandatory. " + errText;
        if (isSearch) {
            return (
                <FormGroup>
                    <Label>{this.getDisplayName(item)} - Från/Till</Label>
                    <Input key={item.name+"-from"}
                        type="date"
                        from_or_to="from"
                        name={item.name}
                        id="from"
                        format={item.format}
                        placeholder="date placeholder"
                        {...(showAsValid && { valid: true })}
                        {...(showAsInvalid && { invalid: true })}
                        onChange={(v) => this.handleKeyUp(v)}
                    />
                  <Input key={item.name+"-to"}
                        type="date"
                        from_or_to="to"
                        name={item.name}
                        id="to"
                        format={item.format}
                        placeholder="date placeholder"
                        {...(showAsValid && { valid: true })}
                        {...(showAsInvalid && { invalid: true })}
                        onChange={(v) => this.handleKeyUp(v)}
                    />
                    <FormFeedback>{errText}</FormFeedback>
                </FormGroup>
            );
        }
        else {
            return (
                <FormGroup>
                    <Label>{this.getDisplayName(item)}</Label>
                    <Input key={Math.random()}
                        type="date"
                        name={item.name}
                        id={item.name}
                        format={item.format}
                        defaultValue={this.getDefaultDate(item)}
                        placeholder="date placeholder"
                        onChange={(v) => this.handleKeyUp(v)}
                    />
                    <FormFeedback>{errText}</FormFeedback>
                </FormGroup>
            );
        }
    }


    getTextForm(item, isSearch) {
        const showAsValid = this.doFormatControl && (!item.mandatory || item.values);
        const showAsInvalid = this.doFormatControl && (item.mandatory && !item.values);
        let errText = "Ex: " + item.placeholder + ".";
        if (item.mandatory) errText = "Mandatory. " + errText;
        return (
            <FormGroup key={isSearch? item.id : Math.random()}>
                <Label>{this.getDisplayName(item)}</Label>
                <Input type="text"
                    id={item.name}
                    name={item.name}
                    format={item.format}
                    defaultValue={!isSearch && item.values? item.values : null}
                    placeholder={item.placeholder}
                    {...(showAsValid && { valid: true })}
                    {...(showAsInvalid && { invalid: true })}
                    onKeyUp={(v) => this.handleKeyUp(v)}
                />
                <FormFeedback>{errText}</FormFeedback>
            </FormGroup>
        );
    }

    getNumberForm(item) {
        const showAsValid = this.doFormatControl && !item.mandatory;
        const showAsInvalid = this.doFormatControl && item.mandatory;
        let errText = "Ex: " + item.placeholder + ".";
        if (item.mandatory) errText = "Mandatory. " + errText;
        return (
            <FormGroup key={item.id}>
                <Label>{this.getDisplayName(item)}</Label>
                <Input type="number"
                    id={item.name}
                    name={item.name}
                    format={item.format}
                    placeholder={item.placeholder}
                    {...(showAsValid && { valid: true })}
                    {...(showAsInvalid && { invalid: true })}
                    onKeyUp={(v) => this.handleKeyUp(v)}
                />
                <FormFeedback>{errText}</FormFeedback>
            </FormGroup>
        );
    }

    getBooleanInput(item) {
        if (item.values === "true") 
            return(
                <Input type="checkbox"
                id={item.name}
                name={item.name}
                checked
                />
            )
            else
             return(
                <Input type="checkbox"
                id={item.name}
                name={item.name}
                />
             )
    }
    getBooleanForm(item) {
        const showAsValid = this.doFormatControl && !item.mandatory;
        const showAsInvalid = this.doFormatControl && item.mandatory;
        let errText = "Ex: " + item.placeholder + ".";
        if (item.mandatory) errText = "Mandatory. " + errText;
        return (
            <FormGroup key={item.id}>
                <Label name={item.name}>{this.getDisplayName(item)}</Label>
                <Input type="select"
                    id={item.name}
                    name={item.name}
                    format={item.format}
                    {...(showAsValid && { valid: true })}
                    {...(showAsInvalid && { invalid: true })}
                    onChange={(v) => this.handleKeyUp(v)}
                    placeholder={item.placeholder}>
                    <option value=''></option>
                    <option value='true' Style='color:green;'>&#10004; True</option>
                    <option value='false' Style='color:red;'>&#10006; False</option>
                </Input>
                <FormFeedback>{errText}</FormFeedback>
            </FormGroup>
        );


    }

    getSelectForm(item) {
        const showAsValid = this.doFormatControl && !item.mandatory;
        const showAsInvalid = this.doFormatControl && item.mandatory;
        let errText = "Ex: " + item.placeholder + ".";
        if (item.mandatory) errText = "Mandatory. " + errText;
        const values = "," + item.values;
        return (
            <FormGroup key={item.id}>
                <Label name={item.name}>{this.getDisplayName(item)}</Label>
                <Input type="select"
                    id={item.name}
                    name={item.name}
                    format={item.format}
                    {...(showAsValid && { valid: true })}
                    {...(showAsInvalid && { invalid: true })}
                    onChange={(v) => this.handleKeyUp(v)}
                    placeholder={item.placeholder}>
                    {values.split(',').map((v, i) => { return <option key={i}>{v}</option> })}
                </Input>
                <FormFeedback>{errText}</FormFeedback>
            </FormGroup>
        );
    }

    getLockedForm(item, value, displayname) {
        let errText = "Exempel: " + item.placeholder + ".";
        if (item.mandatory) errText = "Måste fyllas i. " + errText;
        return (
            <FormGroup key={item.id}>
                <Label>{displayname}</Label>
                <Input type="text"
                    id={item.name}
                    name={item.name}
                    value={value}
                />
                <FormFeedback>{errText}</FormFeedback>
            </FormGroup>
        );
    }

    getForm(item, isSearch=true, metadata = null, isFormLocked = false) {
        if (isFormLocked) {
            let value = "";
            let displayname = "";
            if (metadata != null) {
              if (item.displayname in metadata && metadata[item.displayname].value != null) {
                  value = metadata[item.displayname].value;
                  displayname = item.displayname;
              }
              else if (item.displayname_sv in metadata && metadata[item.displayname_sv].value != null) {
                  value = metadata[item.displayname_sv].value;
                  displayname = item.displayname_sv;
              }
            }
            return this.getLockedForm(item, value, displayname);
        }
        else if (item.type === 'text') return this.getTextForm(item, isSearch);
        else if (item.type === 'number') return this.getNumberForm(item);
        else if (item.type === 'boolean') return this.getBooleanForm(item);
        else if (item.type === 'select' || item.type == 'userlist') return this.getSelectForm(item);
        else if (item.type === 'date') return this.getDateForm(item, isSearch);
    }
}
